import { Box, Typography, useMediaQuery } from "@mui/material";
import logoGemma from "../../../assets/logo/LOGO trimmed.jpg";
import { shades } from "../../../theme";
export const About = () => {
  const isNotMobile = useMediaQuery("(min-width: 600px)");
  const isMediumScreenSize = useMediaQuery("(min-width: 1400px)");
  return (
    <Box
      height="39vh"
      minHeight={!isNotMobile ? "400px" : "auto"}
      margin={isNotMobile ? "150px auto" : "25px auto 0"}
      width={isNotMobile ? "1300px" : "auto"}
      display="flex"
      flexDirection={isNotMobile ? "row" : "column-reverse"}
      justifyContent={isNotMobile ? "space-between" : "center"}
      alignContent={"center"}
      alignItems="center"
    >
      <Box
        alignItems={isNotMobile ? "" : "center"}
        width={isNotMobile ? "600px" : "auto"}
        margin={isNotMobile ? "auto" : "10px"}
        textAlign="left"
      >
        <Typography
          variant="h3"
          fontWeight={"bold"}
          color={shades.secondary[500]}
          marginBottom={"30px"}
          marginLeft="15px"
        >
          ¡Bienvenidos a mi sitio web!
        </Typography>
        <Typography
          color={shades.primary[800]}
          margin="10px"
          fontWeight={"medium"}
        >
          Soy Gemma Zaragüeta, ilustradora nacida en 1983 en Barcelona. Me
          gradué en ilustración en la Escuela Massana de Barcelona en 2007 y
          desde entonces he he colaborado con diversas editoriales, revistas y
          medios, como Teide editorial, Cuadernos de pedagogía, Pol·len
          editorial, Cric-Cric, Jocs al segon, editorial el pirata, MTM
          editores, Barcelona i educació, Jollibre, Santillana y Barça Kids,
          entre otros.
        </Typography>

        <Typography
          color={shades.primary[800]}
          margin="10px"
          fontWeight={"medium"}
        >
          Mi pasión por la ilustración comenzó desde niña, cuando pasaba horas
          dibujando y coloreando. Ahora, con más de una década de experiencia en
          el sector, he desarrollado un estilo propio que combina técnicas
          tradicionales y digitales para crear ilustraciones únicas y llenas de
          vida.
        </Typography>
        <Typography
          color={shades.primary[800]}
          margin="10px"
          fontWeight={"medium"}
        >
          Siéntete libre de navegar por mi portafolio y explorar mi trabajo. Si
          tienes alguna pregunta o te gustaría hablar sobre un proyecto, no
          dudes en contactarme. ¡Gracias por visitar mi sitio!
        </Typography>
      </Box>
      <Box>
        <img
          src={logoGemma}
          alt="gemma_logo"
          width={isMediumScreenSize ? "600px" : isNotMobile ? "400px" : "200px"}
        />
      </Box>
    </Box>
  );
};
