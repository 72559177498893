import loading from "../../assets/loading.gif";
import { Box, useMediaQuery } from "@mui/material";
export const Loading = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Box margin={isMobile?'100px auto':"300px auto"} >
      <img
        src={loading}
        alt="loading_frog"
        style={{ height: isMobile ? "100px" : "200px" }}
      />
    </Box>
  );
};
