import { useState, useRef } from "react";
import {
  Box,
  Button,
  Link,
  Typography,
  TextField,
  useMediaQuery,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import { shades } from "../../../theme";
import { Formik, Form } from "formik";
import * as yup from "yup";

export const Contact = () => {
  const [isSendOk, setIsSendOk] = useState(false);
  const [isSendError, setIsSendError] = useState(null);
  const formRef = useRef(null);
  const isNotMobile = useMediaQuery("(min-width: 600px)");

  const handleSendEmail = async () => {
    const response = await emailjs.sendForm(
      "service_aa5q91p",
      "template_8tczv16",
      formRef.current,
      "user_CbNDMO2ND12WJSmLhhEy7"
    );
    const { status } = response;
    if (status === 200) {
      setIsSendOk(true);
    } else {
      setIsSendError(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width={"75%"}
      minWidth={"350px"}
      margin={isNotMobile ? "60px auto 40px" : "0 auto 0"}
      textAlign={"center"}
    >
      <Typography variant="h2" fontWeight={"bold"} margin="20px 0">
        Contacto
      </Typography>
      <Box display="flex" width="75%">
        <Formik
          onSubmit={handleSendEmail}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form style={{ width: "100%" }} ref={formRef}>
              {isSendOk ? (
                <Box marginBottom={"20px"} width="100%" height="30vh">
                  <Typography
                    variant="h3"
                    fontWeight={"bold"}
                    margin={isNotMobile ? "150px 0 10px 0" : "100px 0 10px 0"}
                    color={shades.secondary[200]}
                  >
                    Mensaje enviado correctamente, gracias!
                  </Typography>
                </Box>
              ) : isSendError ? (
                <Box marginBottom={"20px"} width="100%" height="30vh">
                  <Typography
                    variant="h3"
                    fontWeight={"bold"}
                    margin={isNotMobile ? "150px 0 10px 0" : "100px 0 10px 0"}
                    color={shades.secondary[200]}
                  >
                    Ha ocurrido un error, por favor, inténtelo directamente en
                    el correo electrónico.
                  </Typography>
                  <Link
                    href="mailto:gemmazaragueta@gmail.com Subject=Contacto%20desde%20la%20web%20gemmazaragueta.com"
                    color={shades.secondary[200]}
                    style={{ textDecoration: "underline" }}
                  >
                    Click aquí para enviar un correo electrónico
                  </Link>
                </Box>
              ) : (
                <>
                  <TextField
                    fullWidth
                    type="text"
                    name="name"
                    label="Nombre y apellidos"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ marginBottom: "20px", width: "100%" }}
                  />
                  {errors.name && touched.name && (
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      {errors.name}
                    </div>
                  )}
                  <TextField
                    fullWidth
                    type="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ marginBottom: "20px", width: "100%" }}
                  />
                  {errors.email && touched.email && (
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      {errors.email}
                    </div>
                  )}
                  <TextField
                    fullWidth
                    type="text"
                    name="phone"
                    label="Teléfono"
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ marginBottom: "20px", width: "100%" }}
                  />
                  {errors.phone && touched.phone && (
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      {errors.phone}
                    </div>
                  )}
                  <TextField
                    fullWidth
                    type="text"
                    name="subject"
                    label="Asunto"
                    value={values.subject}
                    error={Boolean(touched.subject && errors.subject)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ marginBottom: "20px", width: "100%" }}
                  />
                  {errors.subject && touched.subject && (
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      {errors.subject}
                    </div>
                  )}
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    type="text"
                    name="message"
                    label="Mensaje"
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ marginBottom: "20px", width: "100%" }}
                  />
                  {errors.message && touched.message && (
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      {errors.message}
                    </div>
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{
                      marginBottom: "20px",
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: shades.primary[100],
                      },
                      backgroundColor: shades.primary[200],
                      color: "white",
                      padding: "15px 40px",
                      borderRadius: "3px",
                    }}
                  >
                    Enviar
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
const initialValues = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};
const validationSchema = yup.object({
  name: yup.string().required("El nombre es obligatorio"),
  email: yup
    .string()
    .email("Email no válido")
    .required("El email es obligatorio"),
  phone: yup.string().required("El teléfono es obligatorio"),
  subject: yup.string().required("El asunto es obligatorio"),
  message: yup.string().required("El mensaje es obligatorio"),
});
