import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, IconButton, useMediaQuery } from "@mui/material";

export const LinksBox = () => {
  const isNotMobile = useMediaQuery("(min-width: 600px)");

  return (
    <Box
      position="absolute"
      top={!isNotMobile ? "5px" : "560px"}
      right={!isNotMobile ? "-3px" : ""}
      left={!isNotMobile ? "" : "63px"}
      zIndex={100}
    >
      <IconButton>
        <a href="https://www.instagram.com/gemma_zaragueta/?hl=es">
          <InstagramIcon fontSize="large" />
        </a>
      </IconButton>
      <IconButton>
        <a href="https://es-es.facebook.com/people/Gemma-Zarag%C3%BCeta-illustradora/100067877719179/">
          <FacebookIcon fontSize="large" />
        </a>
      </IconButton>
      <IconButton>
        <a href="https://www.linkedin.com/in/gemma-zarag%C3%BCeta-410a1928/?originalSubdomain=es">
          <LinkedInIcon fontSize="large" />
        </a>
      </IconButton>
    </Box>
  );
};
