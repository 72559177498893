import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
//Global Components
import { Layout } from "./components/common/layout/Layout";
//import { CustomCookieConsent } from "./components/common/CustomCookieConsent";
//Portfolio Scenes
import { Home } from "./scenes/portfolio/home/Home";
import { About } from "./scenes/portfolio/about/About";
import { Books } from "./scenes/portfolio/books/Books";
import { BookDetails } from "./scenes/portfolio/books/BookDetails";
import { Others } from "./scenes/portfolio/other/Others";
import { OthersDetails } from "./scenes/portfolio/other/OthersDetails";
import { PersonalProjects } from "./scenes/portfolio/personal/PersonalProjects";
import { PersonalProjectsDetails } from "./scenes/portfolio/personal/PersonalProjectsDetails";
import { Contact } from "./scenes/portfolio/contact/Contact";
// //Ecommerce Scenes
// import { ShopHome } from "./scenes/ecommerce/shopHome/ShopHome";
// import { ItemDetails } from "./scenes/ecommerce/itemDetails/ItemDetails";
// import { CartMenu } from "./scenes/ecommerce/global/CartMenu";
// import { Checkout } from "./scenes/ecommerce/checkout/Checkout";
// import { Confirmation } from "./scenes/ecommerce/checkout/Confirmation";

import { useMediaQuery } from "@mui/material";
//Redux
import { useDispatch } from "react-redux";
import { setIsOpenDrawer } from "./state";

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const App = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile === false) {
      dispatch(setIsOpenDrawer(false));
    }
  }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="app">
      <BrowserRouter>
        <Layout>
          <ScrollToTop />
          <Routes>
            {/* ADD PORTFOLIO ROUTES */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/books" element={<Books />} />
            <Route path="books/:bookId" element={<BookDetails />} />
            <Route path="/other_projects" element={<Others />} />
            <Route
              path="other_projects/:projectId"
              element={<OthersDetails />}
            />
            <Route path="/personal_projects" element={<PersonalProjects />} />
            <Route
              path="personal_projects/:projectId"
              element={<PersonalProjectsDetails />}
            />
            <Route path="/contact" element={<Contact />} />
            {/* ECOMMERCE ROUTES */}
            {/* <Route path="/shop" element={<ShopHome />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="checkout/success" element={<Confirmation />} /> */}
            <Route path="*" element={<Home />} />
          </Routes>
          {/* <CartMenu /> */}
        </Layout>
      </BrowserRouter>
      {/* <CustomCookieConsent /> */}
    </div>
  );
};
