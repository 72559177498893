import { useMediaQuery } from "@mui/material";

export const ImageItem = ({ image, type }) => {
  const isNotMobile = useMediaQuery("(min-width: 900px)");
  return (
      <img
        src={image.sizes[2].url}
        alt={image.title}
        style={{
          width: isNotMobile ? "50vw" : "100vw",
          marginX: "auto",
          marginBottom: '25px'
        }}
      />
  );
};
