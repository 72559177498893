import { Box, IconButton, useMediaQuery } from "@mui/material";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";



export const HomeCarousel = ({images}) => {
  const isNotMobile = useMediaQuery("(min-width:600px)");
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      animationHandler="fade"
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      dynamicHeight={false}
      width={isNotMobile ? "70vw" : "95vw"}
      transitionTime={800}
      stopOnHover={false}
      swipeable={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <IconButton
          onClick={onClickHandler}
          sx={{
            position: "absolute",
            top: "50%",
            left: "0",
            color: "grey",
            padding: "5px",
            zIndex: "10",
          }}
        >
          <NavigateBeforeIcon
            sx={{ fontSize: 40, display: `${isNotMobile ? "" : "none"}` }}
          />
        </IconButton>
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <IconButton
          onClick={onClickHandler}
          sx={{
            position: "absolute",
            top: "50%",
            right: "0",
            color: "grey",
            padding: "5px",
            zIndex: "10",
          }}
        >
          <NavigateNextIcon
            sx={{ fontSize: 40, display: `${isNotMobile ? "" : "none"}` }}
          />
        </IconButton>
      )}
    >
      {!!images && Object.values(images).map((texture, index) => (
        <Box
          key={`carousel_pic-${index}`}
          minWidth={isNotMobile ? "800px" : ""}
          minHeight="400px"
        >
          <img
            key={`carousel-image-${index}`}
            src={texture}
            alt={`carousel-${index}`}
            style={{
              width: isNotMobile ? "60%" : "100%",
              objectFit: "fill",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              borderRadius: "10px",
            }}
          />
        </Box>
      ))}
    </Carousel>
  );
};
