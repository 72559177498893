import { Navbar } from "./Navbar";
import { MobileNavbar } from "./MobileNavbar";
import { Footer } from "./Footer";
//import { useLocation } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

export const Layout = ({ children }) => {
  //const { pathname } = useLocation();
  const isNotMobile = useMediaQuery("(min-width: 600px)");
  const isOpenDrawer = useSelector((state) => state.drawer.isOpenDrawer);

  // const isShop =
  //   pathname.includes("shop") ||
  //   pathname.includes("item") ||
  //   pathname.includes("checkout");
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      paddingTop={isNotMobile ? "50px" : 0}
    >
      {!isNotMobile && <MobileNavbar />}
      <Box
        display={"flex"}
        flexDirection="row"
        position={isOpenDrawer ? "relative" : ""}
      >
        {isNotMobile && <Navbar />}
        <Box
          display={"flex"}
          marginX={"auto"}
          marginTop={isNotMobile ? "" : "15px"}
          sx={{
            position: isOpenDrawer ? "relative" : isNotMobile ? "" : "",
            left: "0",
            width: "100%",
            backgroundColor: "white",
            transition: "all 0.9s ease-in-out",
            transform: isOpenDrawer ? "translateY(10%)" : "translateY(0)",
          }}
        >         
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
