import { createSlice, combineReducers } from "@reduxjs/toolkit";

const cartInitialState = {
  isCartOpen: false,
  cart: [],
  items: [],
};
const drawerInitialState = {
  isOpenDrawer: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: cartInitialState,
  reducers: {
    //TODO: getItems and setItems are not working
    getItems: (state) => {
      state.items = JSON.parse(localStorage.getItem("items"));
    },
    setItems: (state, action) => {
      localStorage.setItem("items", JSON.stringify(action.payload));
      state.items = action.payload;
    },

    addToCart: (state, action) => {
      const { item } = action.payload;
      const existingItemIndex = state.cart.findIndex((i) => i.id === item.id);
      if (existingItemIndex >= 0) {
        state.cart[existingItemIndex].count += item.count;
      } else {
        state.cart = [...state.cart, item];
      }
    },

    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload.id);
    },

    increaseCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.id) {
          item.count++;
        }
        return item;
      });
    },

    decreaseCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.id && item.count > 1) {
          item.count--;
        }
        return item;
      });
    },

    setIsCartOpen: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },
  },
});

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: drawerInitialState,
  reducers: {
    setIsOpenDrawer: (state, action) => {
      if (action.payload === undefined) {
        state.isOpenDrawer = !state.isOpenDrawer;
      } else {
        state.isOpenDrawer = action.payload;
      }
    },
  },
});

export const {
  getItems,
  setItems,
  addToCart,
  removeFromCart,
  increaseCount,
  decreaseCount,
  setIsCartOpen,
} = cartSlice.actions;

export const { setIsOpenDrawer } = drawerSlice.actions;

export const combinedReducers = combineReducers({
  cart: cartSlice.reducer,
  drawer: drawerSlice.reducer,
});
// export const cartReducer = cartSlice.reducer;
// export const drawerReducer = drawerSlice.reducer;
