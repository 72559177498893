import { useState } from "react";
import { Box } from "@mui/material";
import { HomeCarousel } from "./HomeCarousel";
import { Loading } from "../../../components/common/Loading";
// imports all images from assets folder
const importAll = (r) =>
  r.keys().reduce((acc, item) => {
    acc[item.replace("./", "")] = r(item);
    return acc;
  }, {});

export const carousel_imports = importAll(
  require.context("../../../assets/home_carousel", false, /\.(png|jpe?g|svg)$/)
);

export const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => {
    setIsLoading(false);
  }, 3500);
  return (
    <Box marginX="auto">
      {isLoading ? <Loading /> : <HomeCarousel images={carousel_imports} />}
    </Box>
  );
};
