import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { ListItem } from "../../../components/portfolio/common/ListItem";
import { useSelector } from "react-redux";

export const Books = () => {
  const [books, setBooks] = useState([]);
  const isNotMobile = useMediaQuery("(min-width: 900px)");
  const isOpenDrawer = useSelector((state) => state.drawer.isOpenDrawer);

  useEffect(() => {
    fetch("https://backend-porfolio-o44d.onrender.com/api/books")
      .then((res) => res.json())
      .then((data) => setBooks(data))
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      minWidth={isNotMobile ? "1250px" : ""}
      width={"90%"}
      textAlign={"center"}
      marginX={"auto"}
      marginTop={isOpenDrawer ? "-220px" : ""}
    >
      <Typography
        variant={isNotMobile ? "h1" : "h3"}
        color="primary"
        marginBottom={"15px"}
      >
        Libros
      </Typography>
        <Box
          minWidth="75%"
          display="grid"
          gridTemplateColumns={`repeat(auto-fill, ${
            isNotMobile ? "500px" : "400px"
          })`}
          columnGap="20px"
          rowGap="20px"
          justifyContent="space-around"
        >
          {books.map((book) => (
            <ListItem item={book} key={book._id} type={"book"} />
          ))}
        </Box>
    </Box>
  );
};
