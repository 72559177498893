import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { ImageItem } from "../../../components/portfolio/common/ImageItem";
import { ScrollToTop } from "../../../App";
import { useSelector } from "react-redux";

export const BookDetails = () => {
  const { bookId } = useParams();
  const [book, setBook] = useState(null);
  const [bookImages, setBookImages] = useState([]);
  const isNotMobile = useMediaQuery("(min-width: 900px)");
  const isOpenDrawer = useSelector((state) => state.drawer.isOpenDrawer);

  useEffect(() => {
    fetch(`https://backend-porfolio-o44d.onrender.com/api/books/${bookId}`)
      .then((res) => res.json())
      .then((data) => {
        setBook(data);
        setBookImages(data.images);
      });
  }, [bookId]);
  return (
    <>
      <ScrollToTop />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={isOpenDrawer ? "-285px" : ""}
        textAlign={"left"}
        width="100%"
      >
        <Typography
          variant={isNotMobile ? "h1" : "h3"}
          color="primary"
          marginBottom={"15px"}
        >
          {book && book.title}
        </Typography>
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="space-around"
          alignItems="center"
          textAlign={"left"}
          width="100%"
        >
          {bookImages.map((image, index) => {
            if (index === 0) {
              return (
                <>
                  <ImageItem image={image} key={image._id} type={"book"} />
                  <Box alignContent={"left"} width="100%" marginBottom={"50px"}>
                    <Typography
                      color="primary"
                      marginBottom="15px"
                      paddingX={"20px"}
                    >
                      {book && book.description}
                    </Typography>
                    <Typography
                      color="primary"
                      marginBottom="15px"
                      paddingX={"20px"}
                    >
                      {book && book["year-location"]}
                    </Typography>
                    <Typography
                      color="primary"
                      marginBottom="15px"
                      paddingX={"20px"}
                    >
                      {book && book.technique}
                    </Typography>
                  </Box>
                </>
              );
            }
            return <ImageItem image={image} key={image._id} type={"book"} />;
          })}
        </Box>
      </Box>
    </>
  );
};
