import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useNavigate, useLocation } from "react-router-dom";
import { shades } from "../../../theme";
import { setIsOpenDrawer } from "../../../state";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import logoGemma from "../../../assets/logo/LOGO trimmed.jpg";
//import { BackButton } from "./BackButton";
import { LinksBox } from "./LinksBox";

export const MobileNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isOpenDrawer = useSelector((state) => state.drawer.isOpenDrawer);

  return (
    <Box padding="15px" position="relative" backgroundColor="white">
      <Box zIndex="10" backgroundColor="white" position="relative">
        <Box backgroundColor="white">
          <IconButton
            onClick={() => {
              dispatch(setIsOpenDrawer());
            }}
          >
            <DehazeIcon sx={{ alignSelf: "left", fontSize: "40px" }} />
          </IconButton>
        </Box>
        <LinksBox />
        {/* <BackButton /> */}
        <Box textAlign="center" zIndex="10" position={"relative"}>
          <img src={logoGemma} alt="gemma_logo" width="160px" />
        </Box>
      </Box>
      <Box
        textAlign="center"
        sx={{
          //the drawer should push the content of the page down and not overlap it with a transition
          //should emerge from the bottom of the navbar
          position: isOpenDrawer ? "relative" : "absolute",
          top: "80",
          left: "0",
          width: "100%",
          backgroundColor: "white",
          zIndex: isOpenDrawer ? 5 : "-5",
          transition: "transform 0.4s ease-in-out, opacity 2s ease-in-out",
          transform: isOpenDrawer ? "translateY(10%)" : "translateY(-150%)",
          opacity: isOpenDrawer ? 1 : 0,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={"column"}
          alignItems="flex-start"
          columnGap="20px"
          paddingLeft={"40px"}
        >
          {location.pathname !== "/" && (
            <Typography
              variant="h5"
              fontWeight={"bold"}
              sx={{
                color: shades.black,
                "&:hover": { cursor: "pointer" },
                textDecoration:
                  location.pathname === "/" ? "underline" : "none",
                textDecorationThickness: "2px",

                margin: "10px 0",
              }}
              onClick={() => {
                dispatch(setIsOpenDrawer());
                navigate("/");
              }}
            >
              HOME
            </Typography>
          )}
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              margin: "10px 0",
              textDecoration:
                location.pathname === "/books" ? "underline" : "none",
              textDecorationThickness: "2px",
            }}
            onClick={() => {
              dispatch(setIsOpenDrawer());
              navigate("/books");
            }}
          >
            LIBROS
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              textDecoration:
                location.pathname === "/other_projects" ? "underline" : "none",
              textDecorationThickness: "2px",
              margin: "10px 0",
            }}
            onClick={() => {
              dispatch(setIsOpenDrawer());
              navigate("/other_projects");
            }}
          >
            OTROS PROYECTOS
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              margin: "10px 0",
              textDecoration:
                location.pathname === "/personal" ? "underline" : "none",
              textDecorationThickness: "2px",
            }}
            onClick={() => {
              dispatch(setIsOpenDrawer());
              navigate("/personal_projects");
            }}
          >
            PERSONAL
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              textDecoration:
                location.pathname === "/about" ? "underline" : "none",
              textDecorationThickness: "2px",
              margin: "10px 0",
            }}
            onClick={() => {
              dispatch(setIsOpenDrawer());
              navigate("/about");
            }}
          >
            ACERCA DE MÍ
          </Typography>
          {/* <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              textDecoration:
                location.pathname === "/shop" ? "underline" : "none",
              textDecorationThickness: "2px",

              margin: "10px 0",
            }}
            onClick={() => {
              dispatch(setIsOpenDrawer());
              navigate("/shop");
            }}
          >
            TIENDA
          </Typography> */}
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              margin: "10px 0",
              textDecoration:
                location.pathname === "/contact" ? "underline" : "none",
              textDecorationThickness: "2px",
            }}
            onClick={() => {
              dispatch(setIsOpenDrawer());
              navigate("/contact");
            }}
          >
            CONTACTO
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
