import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { ImageItem } from "../../../components/portfolio/common/ImageItem";
import { ScrollToTop } from "../../../App";

export const OthersDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [projectImages, setProjectImages] = useState([]);
  const isNotMobile = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    fetch(
      `https://backend-porfolio-o44d.onrender.com/api/other_projects/${projectId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setProject(data);
        setProjectImages(data.images);
      });
  }, [projectId]);

  return (
    <>
      <ScrollToTop />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign={"left"}
        marginX="auto"
        width="100%"
      >
        <Typography
          variant={isNotMobile ? "h1" : "h3"}
          color="primary"
          marginBottom={"15px"}
        >
          {project && project.title}
        </Typography>
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="space-around"
          alignItems="center"
          textAlign={"left"}
          width="100%"
        >
          {projectImages.map((image, index) => {
            if (index === 0) {
              return (
                <>
                  <ImageItem
                    image={image}
                    key={image._id}
                    type={"otherProject"}
                  />
                  <Box alignContent={"left"} width="100%" marginBottom={"50px"}>
                    <Typography
                      color="primary"
                      marginBottom="15px"
                      paddingX={"20px"}
                    >
                      {project && project.description}
                    </Typography>
                    <Typography
                      color="primary"
                      marginBottom="15px"
                      paddingX={"20px"}
                    >
                      {project && project.client}
                    </Typography>
                    <Typography
                      color="primary"
                      marginBottom="15px"
                      paddingX={"20px"}
                    >
                      {project && project.technique}
                    </Typography>
                  </Box>
                </>
              );
            }
            return (
              <ImageItem image={image} key={image._id} type={"otherProject"} />
            );
          })}
        </Box>
      </Box>
    </>
  );
};
