import { Box, Typography, useMediaQuery } from "@mui/material";
import { shades } from "../../../theme";

export const Footer = () => {
  const isNotMobile = useMediaQuery("(min-width: 600px)");
  //why all the red angle brackets in the html tags?
  return (
    <footer>
      <Box
        margin="70px 15px 0"
        padding="40px 0 0"
        backgroundColor={"white"}
        borderTop={`1px solid ${shades.primary[100]}`}
      >
        <Box
          width={isNotMobile ? "65%" : "100%"}
          margin="auto"
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          // rowGap="30px"
          // columnGap="clamp(20px, 30px, 40px)"
        >
          {/* <Box width="clamp(20%, 30%, 40%)">
          <Typography
            variant="h4"
            fontWeight="bold"
            mb="30px"
            color={shades.secondary[500]}
          >
            ECOMMER
          </Typography>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat
          </div>
        </Box>

        <Box>
          <Typography variant="h4" fontWeight="bold" mb="30px">
            About Us
          </Typography>
          <Typography mb="30px">Careers</Typography>
          <Typography mb="30px">Our Stores</Typography>
          <Typography mb="30px">Terms & Conditions</Typography>
          <Typography mb="30px">Privacy Policy</Typography>
        </Box>

        <Box>
          <Typography variant="h4" fontWeight="bold" mb="30px">
            Customer Care
          </Typography>
          <Typography mb="30px">Help Center</Typography>
          <Typography mb="30px">Track Your Order</Typography>
          <Typography mb="30px">Corporate & Bulk Purchasing</Typography>
          <Typography mb="30px">Returns & Refunds</Typography>
        </Box> */}

          <Box
          // width="clamp(20%, 25%, 30%)"
          >
            <Typography variant="h4" fontWeight="bold" mb="30px">
              Contacto
            </Typography>
            <Typography mb="30px" fontWeight="bold">
              Calle Carena, 17, 08196, Sant Cugat del Vallès, Barcelona
            </Typography>
            <Typography
              mb="30px"
              fontWeight="bold"
              sx={{ wordWrap: "break-word" }}
            >
              Email: gemmazaragueta@gmail.com
            </Typography>
            <Typography mb="30px" fontWeight="bold">
              Teléfono (Whatsapp): (+34) 690 283 908
            </Typography>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
