import { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { shades } from "../../../theme";
import { useDispatch } from "react-redux";
import { setIsOpenDrawer } from "../../../state";

export const ListItem = ({ item, type }) => {
  const isNotMobile = useMediaQuery("(min-width: 900px)");
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigateDependingOnType = () => {
    if (type === "book") {
      navigate(`/books/${item._id}`);
    } else if (type === "personal_project") {
      navigate(`/personal_projects/${item._id}`);
    } else if (type === "comercial_design") {
      navigate(`/comercial_designs/${item._id}`);
    } else if (type === "other_projects") {
      navigate(`/other_projects/${item._id}`);
    }
  };
  return (
    <Box
      margin="0 auto 25px"
      display="flex"
      flexDirection="column"
      textAlign={'left'}
      alignContent={"left"}
      alignItems="left"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      position="relative"
      sx={{
        ":hover": {
          opacity: 0.8,
        },
        borderRadius: "10px",
      }}
    >
      <img
        src={item.images[0].sizes[1].url}
        alt={item?.title}
        style={{
          width: isNotMobile ? "500px" : "400px",
          height: isNotMobile ? "500px" : "400px",
          objectFit: "cover",
          cursor: "pointer",
        }}
        onClick={() => {
          handleNavigateDependingOnType();
          dispatch(setIsOpenDrawer(false));
        }}
      />
      <Box
        display={!isNotMobile ? "block" : isHovered ? "block" : "none"}
        padding="0 2%"
      >
        <Box display="flex" justifyContent="space-between" zIndex={1000}>
          <Box
            display="flex"
            alignItems="center"
            backgroundColor={shades.neutral[200]}
            borderRadius="3px"
          >
            <Typography
              color={shades.primary[600]}
              padding="3px 5px"
              fontWeight={"bold"}
            >
              {item.title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
