// import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
// import {
//   PersonOutline,
//   ShoppingBagOutlined,
//   MenuOutlined,
//   SearchOutlined,
// } from "@mui/icons-material";

import { useNavigate, useLocation } from "react-router-dom";
import { shades } from "../../../theme";
// import { setIsCartOpen } from "../../../state";
import logoGemma from "../../../assets/logo/LOGO trimmed.jpg";
//import { BackButton } from "./BackButton";
import { LinksBox } from "./LinksBox";

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  // const cart = useSelector((state) => state.cart.cart);

  return (
    <Box
      display="inline-block"
      alignItems="flex-start"
      justifyContent="space-between"
      padding={"0 20px"}
      margin={"0 20px"}
      minWidth="250px"
      height="100%"
      color="black"
    >
      <Box
        width="100%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={"column"}
      >
        {/* <BackButton /> */}
        <Box
          onClick={() => navigate("/")}
          sx={{
            "&:hover": { cursor: "pointer" },
            margin: "100px 0 30% 0",
          }}
        >
          <img src={logoGemma} alt="gemma_logo" width="160px" />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={"column"}
          alignItems="flex-start"
          columnGap="20px"
        >
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              margin: "10px 0",
              textDecoration:
                location.pathname === "/books" ? "underline" : "none",
              textDecorationThickness: "2px",
            }}
            onClick={() => navigate("/books")}
          >
            LIBROS
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              textDecoration:
                location.pathname === "/other_projects" ? "underline" : "none",
              textDecorationThickness: "2px",
              margin: "10px 0",
            }}
            onClick={() => navigate("/other_projects")}
          >
            OTROS PROYECTOS
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              margin: "10px 0",
              textDecoration:
                location.pathname === "/personal" ? "underline" : "none",
              textDecorationThickness: "2px",
            }}
            onClick={() => navigate("/personal_projects")}
          >
            PERSONAL
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              textDecoration:
                location.pathname === "/about" ? "underline" : "none",
              textDecorationThickness: "2px",
              margin: "10px 0",
            }}
            onClick={() => navigate("/about")}
          >
            ACERCA DE MÍ
          </Typography>
          {/* <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              textDecoration:
                location.pathname === "/shop" ? "underline" : "none",
              textDecorationThickness: "2px",

              margin: "10px 0",
            }}
            onClick={() => navigate("/shop")}
          >
            TIENDA
          </Typography> */}
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{
              color: shades.black,
              "&:hover": { cursor: "pointer" },
              margin: "10px 0",
              textDecoration:
                location.pathname === "/contact" ? "underline" : "none",
              textDecorationThickness: "2px",
            }}
            onClick={() => navigate("/contact")}
          >
            CONTACTO
          </Typography>

          {/* <IconButton sx={{ color: "black" }}>
            <SearchOutlined />
          </IconButton>
          <IconButton sx={{ color: "black" }}>
            <PersonOutline />
          </IconButton>
          <Badge
            badgeContent={cart.length}
            color="secondary"
            invisible={cart.length === 0}
            sx={{
              "& .MuiBadge-badge": {
                right: 5,
                top: 5,
                padding: "0 4px",
                height: "14px",
                minWidth: "13px",
              },
            }}
          >
            <IconButton
              onClick={() => dispatch(setIsCartOpen({}))}
              sx={{ color: "black" }}
            >
              <ShoppingBagOutlined />
            </IconButton>
          </Badge>
          <IconButton sx={{ color: "black" }}>
            <MenuOutlined />
          </IconButton> */}
          <LinksBox />
        </Box>
      </Box>
    </Box>
  );
};
